import React, { useEffect, useRef, useState } from "react";
import EmojiPicker from 'emoji-picker-react';
import { useAppSelector } from "../../redux/hooks";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { parseTimestamp } from "../../helper/helpers";
import DialogTitle from "@mui/material/DialogTitle";
import moment from 'moment';
import {
  Image,
  File,
  TrashBin,
  FaceHappy,
  ChevronLeft,
} from "akar-icons";
import { useDispatch } from "react-redux";
import historicalService from "../../services/historical/historicalService";
import MessageItem from "./widget/MessageItem";
import useMobileDetect from "../../services/movil/useMobileDetect";
import { reset } from "../../redux/features/selectorSlice";
import useProfile from '../../services/profile/useGenerateProfile';

export default function ChatBoard({ chatData, pausedOrNot }: any) {
  const [message, setMessage] = useState("");
  const { isMobile } = useMobileDetect();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const chatListRef = useRef<HTMLOListElement>(null);
  const [stoppedNumbers, setStoppedNumbers] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const fileInputRefImage = useRef<HTMLInputElement>(null);
  const fileInputRefPDF = useRef<HTMLInputElement>(null);
  const [dateShow, setDateShow] = useState("Hoy");
  const [isUploading, setIsUploading] = useState(false);
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const phoneContact = useAppSelector(
    (state: any) => state.phoneContactReducer.value
  ) as any;
  const selection = useAppSelector((state: any) => state.selectorReducer.value);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(true);
  const [isDisabledChat, setIsDisabledChat] = useState(true);
  const toggleDropdown2 = () => {
    if (isOpen) {
      setIsOpen2(false);
      setIsOpen(false);
    } else {
      setIsOpen2(!isOpen2);
    }

  };
  useEffect(() => {
    setStoppedNumbers(pausedOrNot);
  }, [pausedOrNot]);

  const resetChat = () => {
    setIsOpenChat(false);
    setTimeout(() => {
      dispatch(reset());
    }, 1000);

  }

  function callMessageStoppedAll() {
    new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
      historicalService.getMessageStoppedAll().then((res) => {
        if (res.status === 200) {
          let data = res.data.messages_stopped.some(
            (number: any) => number === selection
          );

          setStoppedNumbers(data);
        }
      });
    });
  }

  async function manualMode() {
    const manualModeOn = {
      "4": "4",
      telefono: selection,
    };

    const response = await historicalService.postTurnManualMode(manualModeOn);
    console.log("Respuesta de Manual==>>>", response.data);
    return response.data;
  }

  //----------Activacion del Modo Automatico ----------
  async function automaticMode() {
    const manualModeOff = {
      telefono: selection,
    };

    return await historicalService.postTurnAutomaticMode(manualModeOff);
  }

  //-----------Modal de Confirmacion -------------------
  const handleConfirmOpen = () => {
    setOpen(true);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  const handleConfirmOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleConfirmCloseAlert = () => {
    setOpenAlert(false);
  };

  //----------Handler de Activacion y Desactivacion del Chatbot ----------
  function handleClickActivar() {
    automaticMode();
    setOpen(false);

    callMessageStoppedAll();
  }

  function handleClickDesactivar() {
    manualMode();
    setOpen(false);

    callMessageStoppedAll();
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (filesRef.current && (filesRef.current as HTMLElement).contains(event.target)) {
        return;
      } else {
        setIsOpen2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //------------Guardado de Cambios----------
  const handleInputChange = (event: any) => {
    setMessage(event.target.value);
  };

  //------------ No Leido -----------------------
  async function handleUnreadMsgSetting() {
    const unreadMsg = {
      numero_telefono: selection,
    };

    const response = await historicalService.postMessageNotReaded(unreadMsg);
    console.log("Respuesta Marcado No Leido ===>", response);
    return response;
  }

  const [cursorPosition, setCursorPosition] = useState(0);

  const handleTextareaSelect = (e: any) => {
    setCursorPosition(e.target.selectionStart);
  };

  const handleEmojiClick = (e: any) => {
    const start = message.substring(0, cursorPosition);
    const end = message.substring(cursorPosition, message.length);
    setMessage(start + e.emoji + end);
  };

  //-------- Handler del Envio Manual----------
  async function enviarMessage() {
    if (selectedFile != null) {
      setIsUploading(true);

      try {

        const response = await historicalService.postSentMessageManualFile(selection, selectedFile);
        if (response === undefined) {
          setIsUploading(false);
        } else if (response.message === "File uploaded successfully") {
          setImagePreview(null);
          setPdfPreview(null);
          setSelectedFile(null);
          setIsUploading(false);
        } else {
          setIsUploading(false);
        }
      } catch (error) {
        setIsUploading(false);
        console.error("Error al enviar el mensaje:", error);
      } finally {
        setIsUploading(false);
        setIsSendingMessage(false);
      }
      return;
    }
    if (isSendingMessage) return;

    setIsSendingMessage(true);

    const messageAdmin = {
      telefono: selection,
      mensaje: message,
    };

    try {
      const response = await historicalService.postSentMessageManual(
        messageAdmin
      );
      if (response.status === 200) {
        setMessage("");
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    } finally {
      setIsSendingMessage(false);
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      // Call your function here
      enviarMessage();
    }
  };

  function findChatDataByNumber(number: any) {
    return chatData.filter((client: any) => client.number === number)[0];
  }

  useEffect(() => {
    console.log(message);

  }, [message]);

  const messagesEndRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      // @ts-ignore
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  useEffect(() => {
    if (selection === "") return;
    let date = findChatDataByNumber(selection).chat[findChatDataByNumber(selection).chat.length - 1].timestamp;
    let result = parseTimestamp(date);
    const today = moment().format('DD/MM/YYYY');
    const yesterday = moment().subtract(1, 'days').format('DD/MM/YYYY');
    const lastday = moment().subtract(2, 'days').format('DD/MM/YYYY');
    if (result["date"] === today) {
      setDateShow("Hoy");
    } else if (result["date"] === yesterday) {
      setDateShow(result["day"]);
    } else if (result["date"] === lastday) {
      setDateShow(result["day"]);
    } else {
      setDateShow(result["date"]);
    }
    setShouldScroll(true);
  }, [selection]);

  useEffect(() => {
    if (selection === "") return;
    const messages = findChatDataByNumber(selection).chat;
    const userMessages = messages.filter((message: any) => message.role === 'user');
    const lastUserMessage = userMessages[userMessages.length - 1];
    const lastMessageDate = new Date(lastUserMessage.timestamp);
    const now = new Date();
    const differenceInMilliseconds = now.getTime() - lastMessageDate.getTime();
    const differenceInHours = differenceInMilliseconds / 3600000;
    console.log(differenceInHours)
    if (differenceInHours >= 24) {
      setIsDisabledChat(true)
    } else {
      setIsDisabledChat(false)
    }
  }, [selection]);

  useEffect(() => {
    setIsOpenChat(true);
    setImagePreview(null);
    setPdfPreview(null);
    setSelectedFile(null);
  }, [selection]);


  const [getEmoji, setGetEmoji] = useState(false);
  const handleScroll = () => {
    if (chatListRef.current === null) return null;
    const chatItems = chatListRef.current.children;
    const chatItemsArray = Array.from(chatItems);
    for (const element of chatItemsArray) {
      const chatItem = element as HTMLElement;
      const rect = chatItem.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        const timestampElement = chatItem.querySelector('.message_assistant')?.querySelector(".body_msg_assistant")?.querySelector(".hidden");
        if (timestampElement) {
          if (timestampElement.textContent !== null) {
            let result = parseTimestamp(timestampElement.textContent);
            const today = moment().format('DD/MM/YYYY');
            const yesterday = moment().subtract(1, 'days').format('DD/MM/YYYY');
            const lastday = moment().subtract(2, 'days').format('DD/MM/YYYY');
            if (result["date"] === today) {
              setDateShow("Hoy");
            } else if (result["date"] === yesterday) {
              setDateShow(result["day"]);
            } else if (result["date"] === lastday) {
              setDateShow(result["day"]);
            } else {
              setDateShow(result["date"]);
            }
          }
        }
        break;
      }
    }
  };
  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
      setShouldScroll(false);
    }
  }, [shouldScroll]);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [pdfPreview, setPdfPreview] = useState<string | null>(null);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    if (file.size > maxFileSize) {
      handleConfirmOpenAlert();
      return;
    }
    setPdfPreview(null);
    if (e.target.files[0]) {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handlePDFChange = (e: any) => {
    const file = e.target.files[0];
    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    if (file.size > maxFileSize) {
      handleConfirmOpenAlert();
      return;
    }
    setImagePreview(null);
    if (e.target.files[0]) {
      setPdfPreview(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };
  return (
    <>

      {findChatDataByNumber(selection) && (
        <div className={`chatBoard_Container rounded-md ml-3 p-3 ${isOpenChat && isMobile ? " " : "Disable-chat"}`}>
          <div className="flex flex-row items-center justify-between bg-white rounded-sm">
            <div className="text-sm xl:text-[16px] flex gap-3 font-medium xl:font-semibold pl-3 py-2">
              {isMobile && <div className="close-chat"> <button className="btn-back" onClick={() => { resetChat() }}> <ChevronLeft size={16} /> </button> </div>}
              <div style={{ background: phoneContact[selection] ? useProfile.useNameToColor(phoneContact[selection]) : useProfile.useNameToColor(selection) }} className="rounded-full flex bg-gray-100 w-10 h-10 profile_img">{phoneContact[selection] ? useProfile.useInitials(phoneContact[selection]) : useProfile.useInitials(selection)}</div>
              <div className="flex flex-col">
                <h3 className="name_identifier">
                  {phoneContact[selection]}
                </h3>
                <h2 className="phone_identifier flex items-center mb-1">
                  {selection}
                </h2>
              </div>
            </div>
            <div className="text-[14px] xl:text-[16px] font-medium xl:font-semibold">
              {/*<button
                className="buttonNoLeido rounded-md px-3 py-1"
                onClick={handleUnreadMsgSetting}
              >
                {" "}
                Marcar como no Leido
              </button>*/}
            </div>
            <div className="text-[14px] xl:text-[16px] p-center-chat font-semibold xl:font-bold">
              {!stoppedNumbers && (
                <button onClick={handleConfirmOpen} className="btn_desactivar">
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0.625H8C7.76794 0.625 7.54538 0.717187 7.38128 0.881282C7.21719 1.04538 7.125 1.26794 7.125 1.5V11.5C7.125 11.7321 7.21719 11.9546 7.38128 12.1187C7.54538 12.2828 7.76794 12.375 8 12.375H10.5C10.7321 12.375 10.9546 12.2828 11.1187 12.1187C11.2828 11.9546 11.375 11.7321 11.375 11.5V1.5C11.375 1.26794 11.2828 1.04538 11.1187 0.881282C10.9546 0.717187 10.7321 0.625 10.5 0.625ZM10.625 11.5C10.625 11.5332 10.6118 11.5649 10.5884 11.5884C10.5649 11.6118 10.5332 11.625 10.5 11.625H8C7.96685 11.625 7.93505 11.6118 7.91161 11.5884C7.88817 11.5649 7.875 11.5332 7.875 11.5V1.5C7.875 1.46685 7.88817 1.43505 7.91161 1.41161C7.93505 1.38817 7.96685 1.375 8 1.375H10.5C10.5332 1.375 10.5649 1.38817 10.5884 1.41161C10.6118 1.43505 10.625 1.46685 10.625 1.5V11.5ZM4 0.625H1.5C1.26794 0.625 1.04538 0.717187 0.881282 0.881282C0.717187 1.04538 0.625 1.26794 0.625 1.5V11.5C0.625 11.7321 0.717187 11.9546 0.881282 12.1187C1.04538 12.2828 1.26794 12.375 1.5 12.375H4C4.23206 12.375 4.45462 12.2828 4.61872 12.1187C4.78281 11.9546 4.875 11.7321 4.875 11.5V1.5C4.875 1.26794 4.78281 1.04538 4.61872 0.881282C4.45462 0.717187 4.23206 0.625 4 0.625ZM4.125 11.5C4.125 11.5332 4.11183 11.5649 4.08839 11.5884C4.06495 11.6118 4.03315 11.625 4 11.625H1.5C1.46685 11.625 1.43505 11.6118 1.41161 11.5884C1.38817 11.5649 1.375 11.5332 1.375 11.5V1.5C1.375 1.46685 1.38817 1.43505 1.41161 1.41161C1.43505 1.38817 1.46685 1.375 1.5 1.375H4C4.03315 1.375 4.06495 1.38817 4.08839 1.41161C4.11183 1.43505 4.125 1.46685 4.125 1.5V11.5Z" fill="white" />
                  </svg>
                  Pausar Bot
                </button>
              )}
              {
                <Dialog open={open} onClose={handleConfirmClose}>
                  <DialogTitle>Detener Bot</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Estas seguro que quieres detener respuesta automatica?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmClose}>Cancel</Button>
                    <Button onClick={handleClickDesactivar} color="error">
                      Confirmar
                    </Button>
                  </DialogActions>
                </Dialog>
              }
              {
                <Dialog open={openAlert} onClose={handleConfirmCloseAlert}>
                  <DialogTitle>Alerta</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      El formato de Imagen o pdf excede el peso permitido
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmCloseAlert} color="error">
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              }
              {stoppedNumbers && (
                <button onClick={handleClickActivar} disabled={isDisabledChat} style={{display: isDisabledChat? "none":"flex"}} className="btn_activar">
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6556 5.9418L2.65063 0.43367C2.46178 0.318544 2.24577 0.255585 2.02464 0.25122C1.80351 0.246855 1.58518 0.301239 1.39194 0.408823C1.19869 0.516406 1.03746 0.673336 0.924678 0.863595C0.811901 1.05385 0.751625 1.27063 0.75 1.4918V12.508C0.751625 12.7292 0.811901 12.946 0.924678 13.1362C1.03746 13.3265 1.19869 13.4834 1.39194 13.591C1.58518 13.6986 1.80351 13.753 2.02464 13.7486C2.24577 13.7443 2.46178 13.6813 2.65063 13.5662L11.6556 8.05804C11.8372 7.94751 11.9873 7.79212 12.0914 7.6068C12.1956 7.42149 12.2503 7.21249 12.2503 6.99992C12.2503 6.78735 12.1956 6.57835 12.0914 6.39304C11.9873 6.20772 11.8372 6.05233 11.6556 5.9418ZM2.25 12.053V1.9468L10.51 6.99992L2.25 12.053Z" fill="white" />
                  </svg>
                  Iniciar Bot
                </button>
              )}
            </div>
          </div>
          <hr className="chat-hr" />
          <div className="flex flex-row items-center justify-between bg-white rounded-sm">
            <div className="text-sm xl:text-[16px] font-medium xl:font-semibold flex m-auto p-2">
              <span className="text-date-chat">{dateShow}</span>
            </div>
          </div>
          {isUploading && <div className="loading-img gap-2">
            <svg width={"12px"} height={"12px"} className="animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
            </svg> Subiendo imagen o PDF...</div>}
          <ul
            ref={chatListRef} onScroll={handleScroll}
            className={
              imagePreview != null || pdfPreview != null
                ? "message_list-60 text-[14px] xl:text-[16px]"
                : "message_list text-[14px] xl:text-[16px]"
            }
          >
            {findChatDataByNumber(selection).chat.map(
              (item: any, index: any) => (
                <MessageItem key={item.id || index} index={index} item={item} />
              )
            )}
            <div ref={messagesEndRef} />
          </ul>
          <div>
            {(!stoppedNumbers) && (
              <div className="mt-2 text-[14px] xl:text-[16px]">
                <input
                  type="text"
                  placeholder="Debes apagar el Chatbot para responder Manualmente"
                  className="input_manual_message w-4/5"
                  disabled
                />
                <button disabled></button>
              </div>
            )}
            {((stoppedNumbers && pdfPreview != null) ||
              imagePreview != null) && (
                <div className="w-full h-20 upload-files-card items-center p-3 mt-3 text-[14px] xl:text-[16px]">
                  <p className="">Archivo Seleccionado:</p>
                  <br />
                  {pdfPreview && (
                    <div className="upload-files">
                      <button
                        className="btn-upload-clear"
                        disabled={isDisabledChat}
                        onClick={() => setPdfPreview(null)}
                      >
                        <TrashBin strokeWidth={3} size={12} />
                      </button>
                      <object
                        data={pdfPreview}
                        type="application/pdf"
                        style={{
                          width: "75px",
                          height: "75px",
                          objectFit: "contain",
                          overflow: "none",
                          margin: "auto",
                        }}
                      >
                        <p>Este navegador no soporta la visualización de PDFs.</p>
                      </object>
                    </div>
                  )}
                  {imagePreview && (
                    <div className="upload-files">
                      <button
                        className="btn-upload-clear"
                        disabled={isDisabledChat}
                        onClick={() => setImagePreview(null)}
                      >
                        <TrashBin strokeWidth={3} size={12} />
                      </button>
                      <img
                        src={imagePreview}
                        alt="Vista previa de la imagen seleccionada"
                        style={{
                          width: "90px",
                          height: "75px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            {stoppedNumbers && (
              <div ref={filesRef} className="w-full h-20 flex items-center p-3 mt-3 text-[14px] xl:text-[16px]">
                <button
                  className={`btn_manual_message ${isSendingMessage ? "btn_sending" : ""
                    }`}
                  onClick={toggleDropdown2}
                  disabled={isSendingMessage || isDisabledChat}
                >
                  <svg width="16" height="16" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6294 1.98674C11.5437 1.07247 12.7837 0.558838 14.0767 0.558838C15.3697 0.558838 16.6097 1.07247 17.524 1.98674C18.4382 2.90102 18.9519 4.14104 18.9519 5.43401C18.9519 6.72699 18.4382 7.96701 17.524 8.88128L9.09143 17.3138C8.83108 17.5742 8.52198 17.7809 8.18178 17.9219C7.84159 18.0628 7.47695 18.1354 7.10869 18.1355C6.36496 18.1356 5.65164 17.8403 5.12564 17.3145C4.59965 16.7887 4.30407 16.0755 4.30394 15.3318C4.3038 14.5881 4.59911 13.8747 5.12491 13.3487L13.3055 5.1667L14.3425 6.20374L6.16048 14.3843C6.03613 14.5087 5.93748 14.6563 5.87018 14.8188C5.80288 14.9813 5.76824 15.1554 5.76824 15.3313C5.76824 15.5071 5.80288 15.6813 5.87018 15.8438C5.93748 16.0062 6.03613 16.1539 6.16048 16.2782C6.28484 16.4026 6.43247 16.5012 6.59495 16.5685C6.75743 16.6358 6.93157 16.6705 7.10744 16.6705C7.2833 16.6705 7.45745 16.6358 7.61993 16.5685C7.78241 16.5012 7.93004 16.4026 8.05439 16.2782L16.4869 7.84571C16.8036 7.529 17.0548 7.15301 17.2263 6.73921C17.3977 6.32541 17.4859 5.88191 17.4859 5.43401C17.4859 4.98612 17.3977 4.54261 17.2263 4.12881C17.0548 3.71501 16.8036 3.33902 16.4869 3.02232C16.1702 2.70561 15.7942 2.45438 15.3804 2.28298C14.9666 2.11158 14.5231 2.02336 14.0752 2.02336C13.6273 2.02336 13.1838 2.11158 12.77 2.28298C12.3562 2.45438 11.9802 2.70561 11.6635 3.02232L2.98053 11.7068C1.97326 12.7392 1.4134 14.1269 1.42228 15.5692C1.43116 17.0116 2.00807 18.3923 3.02797 19.4122C4.04788 20.4321 5.42861 21.009 6.87095 21.0179C8.31329 21.0268 9.70102 20.4669 10.7334 19.4596L19.1659 11.0257L20.203 12.0627L11.769 20.4952C10.4583 21.7588 8.70394 22.4574 6.88342 22.4406C5.0629 22.4239 3.3217 21.6932 2.03444 20.4057C0.747184 19.1183 0.0167458 17.377 0.000284356 15.5565C-0.0161771 13.7359 0.682654 11.9817 1.94642 10.6712L10.6294 1.98674Z" fill="white" />
                  </svg>
                </button>
                {isOpen2 && !isOpen && (
                  <div className="origin-center absolute z-30 right-0 mt-2 w-44 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <ul role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <li>
                        <button
                          className="block px-4 flex flex-row gap-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={(e) => { fileInputRefImage.current?.click(); toggleDropdown2() }}
                          disabled={isSendingMessage}
                        >
                          <Image strokeWidth={3} size={16} /> Imágenes
                        </button>
                      </li>

                      <hr></hr>
                      <li>
                        <button
                          className="block flex flex-row gap-2 px-4 py-2 text-sm text-gray-700 z-30 hover:bg-gray-100"
                          onClick={(e) => { fileInputRefPDF.current?.click(); toggleDropdown2() }}
                          disabled={isSendingMessage}
                        >
                          <File strokeWidth={3} size={16} /> PDF
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
                <button
                  className={`btn_manual_message emoji-phone ${isSendingMessage ? "btn_sending" : ""
                    }`}
                  onClick={() => { setGetEmoji(!getEmoji) }}
                  disabled={isSendingMessage || isDisabledChat}
                >
                  <FaceHappy strokeWidth={3} size={16} />
                </button>
                <textarea
                  placeholder={(!isDisabledChat)? "Escribe un mensaje" : "Se podrá enviar mensajes una vez el cliente escriba"}
                  className="w-4/5 h-full py-1 chat-yavendio px-2 mr-2 resize-none"
                  onKeyDown={handleKeyPress}
                  onSelect={handleTextareaSelect}
                  value={message}
                  onFocus={() => setGetEmoji(false)}
                  onChange={handleInputChange}
                  style={{
                    overflowY: "auto",
                    display: isDisabledChat ? "none" : "block",
                  }}
                />
                <textarea
                  placeholder={(!isDisabledChat)? "Escribe un mensaje" : "Se podrá enviar mensajes una vez el cliente escriba"}
                  className="w-4/5 h-full py-1 chat-yavendio px-2 mr-2 resize-none"
                  disabled={true}
                  style={{
                    overflowY: "auto",
                    display: isDisabledChat ? "block" : "none",
                  }}
                />
                <button
                  className={`btn_manual_message ${isSendingMessage ? "btn_sending" : ""
                    }`}
                  onClick={enviarMessage}
                  style={{ display: isDisabledChat ? "none" : "flex" }}
                  disabled={isSendingMessage}
                >
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1644_4272)">
                      <path d="M18.5358 1.27178C18.5767 1.16955 18.5867 1.05757 18.5646 0.94971C18.5425 0.84185 18.4892 0.742854 18.4113 0.664997C18.3335 0.587139 18.2345 0.533844 18.1266 0.511718C18.0187 0.489593 17.9068 0.499609 17.8045 0.540526L1.43916 7.0869H1.43803L0.929533 7.2894C0.833222 7.32782 0.749404 7.39211 0.687338 7.47517C0.625272 7.55824 0.587373 7.65684 0.577828 7.76009C0.568283 7.86334 0.587463 7.96722 0.63325 8.06025C0.679036 8.15329 0.749647 8.23185 0.837283 8.28728L1.29853 8.57978L1.29966 8.58203L6.91903 12.1573L10.4943 17.7767L10.4965 17.7789L10.789 18.2402C10.8446 18.3274 10.9232 18.3977 11.0162 18.4432C11.1092 18.4887 11.2129 18.5077 11.3159 18.498C11.419 18.4884 11.5174 18.4505 11.6003 18.3885C11.6832 18.3265 11.7474 18.2429 11.7858 18.1468L18.5358 1.27178ZM16.4737 3.39803L8.04291 11.8288L7.80103 11.4485C7.75671 11.3787 7.69756 11.3196 7.62778 11.2753L7.24753 11.0334L15.6783 2.60265L17.0035 2.07278L16.4737 3.39803Z" fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1644_4272">
                        <rect width="18" height="18" fill="white" transform="translate(0.576416 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <input
                  type="file"
                  accept=".jpg,.png"
                  ref={fileInputRefImage}
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChange(e)}
                />
                <input
                  type="file"
                  accept=".pdf"
                  ref={fileInputRefPDF}
                  style={{ display: "none" }}
                  onChange={(e) => handlePDFChange(e)}
                />
              </div>
            )}
          </div>
          <EmojiPicker open={getEmoji} onEmojiClick={(e) => { handleEmojiClick(e) }} className="chatbotEmojis" />
        </div>
      )}
    </>
  );
}
