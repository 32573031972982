import React from "react";
import { useState, useRef } from "react";
import loginService from '../services/login/loginService';
import { Link } from "react-router-dom";

import { useAuth } from '../context/auth/AuthContext';


export default function CredentialsForm() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);

  let { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const credentials = {
      username: emailRef.current?.value,
      password: passwordRef.current?.value
    }

    try {
      const user = await loginService.signIn(credentials);

      if (user.status === 200) {
        setError(null);
        login({
          email: credentials.username,
          ...user.data
        });
      } else {
        setError("Correo y/o contraseña incorrectos");
      }
    } catch (error) {
      setError("Correo y/o contraseña incorrectos");
      console.error(error);
    }
  };

  return (
    <form
      className="w-full mt-8 text-xl text-black font-semibold flex flex-col"
      onSubmit={handleSubmit}
    >
      <div className={(error) ? "error-login email_input" : "email_input"}>
        <input
          type="email"
          name="email"
          ref={emailRef}
          placeholder="ejemplo@email.com"
          required
          className="input-default email_input"
        />
      </div>
      <div className={(error) ? "error-login password_input" : "password_input"}>
        <input
          type="password"
          name="password"
          ref={passwordRef}
          placeholder="8+ Caracteres seguros"
          required
          className="input-default rounded-md "
        />
      </div>
      <Link to="/forget-password" className="forgot-paasword">¿Olvidaste tu contraseña?</Link>
      {error && (
        <span className="login-error">
          {error}
        </span>
      )}
      <button
        type="submit"
        className="btn btn-primary mt-4"
      >
        Ingresar
      </button>
    </form>
  );

}
